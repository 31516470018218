import React from "react";
import { FiPhone } from "react-icons/fi";
import { FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
  return (
      <div className="footer-section">
        <div className="footer-section-columns">
            <span className="footer-section-title">Suporte Scanitec</span>
            <div className="footer-line"></div>
            <span>Segunda a sexta das 08:00 às 18:00</span>
            <span></span>
            <span>(31) 99766-7450</span>
            <span>(31) 99349-1601</span>
            <div className="footer-line"></div>
            <span>suporte@scanitec.com.br</span>
            <span>suporte01@scanitec.com.br</span>
        </div>
        <div className="footer-section-columns">
            <span className="footer-section-title">Suporte Scanydrive</span>
            <div className="footer-line"></div>
            <span>Segunda a sexta das 08:00 às 18:00</span>
            <span></span>
            <span>(31) 9 9211-3611</span>
            <span>(31) 3408-6232</span>
            <div className="footer-line"></div>
            <span>Segunda a sexta das 18:00 às 22:00</span>
            <span>Sábado das 08:00 às 14:00</span>
            <span></span>
            <span>(31) 9 9117-4920</span>
            <div className="footer-line"></div>
            <span>suportescanydrive@scanitec.com.br</span>
        </div>
        <div className="footer-section-columns">
            <span className="footer-section-title">Central de atendimento</span>
            <div className="footer-line"></div>
            <span>(31) 3408-6200</span>
            <div className="footer-line"></div>
            <span className="footer-section-title"><FiPhone/> Plantão de Vendas</span>
            <div className="footer-line"></div>
            <span>(31) 9 7599-5260</span>
            <span>(31) 9 9464-5151</span>
            <div className="footer-line"></div>
            <span>comercial1@scanitec.com.br</span>
            <span>comercial2@scanitec.com.br</span>
            <div className="footer-line"></div>
            <span className="footer-section-title"><FaMapMarkerAlt/> Localização</span>
            <div className="footer-line"></div>
            <span>Rua Mercedes Luísa Miranda, 320</span>
            <span>Maria Goretti</span>
            <span></span>
            <span>Belo Horizonte - MG</span>
        </div>
      </div>
  );
};

export default Footer;
