import React from "react";

const Contact = () => {
  return (
    <div id="contact" className="contact-page-wrapper">
      <h1 className="primary-heading">Como podemos ajudá-lo?</h1>
      <h1 className="primary-heading">Entre em contato</h1>
      <div className="contact-form-container-two">
        <div className="contact-form-container">
          <input type="text" placeholder="Nome" /> 
        </div>
        <div className="contact-form-container">
          <input type="text" placeholder="Telefone" /> 
        </div>
        <div className="contact-form-container">
          <input type="text" placeholder="E-mail" />
        </div>
        <div className="contact-form-container-textarea">
          <textarea placeholder="Mensagem" rows="4"></textarea>
        </div>
        <button className="secondary-button">Enviar</button>
      </div>
    </div>
  );
};

export default Contact;
