import React, { useState, useEffect } from "react";
import RightIcon from '@mui/icons-material/ArrowForwardIos';
import LeftIcon from '@mui/icons-material/ArrowBackIos';

const ProductCarousel = ({ products }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [numVisibleProducts, setNumVisibleProducts] = useState(3);

  const handlePrevClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - numVisibleProducts);
    }
  };

  const handleNextClick = () => {
    if (startIndex + numVisibleProducts < products.length) {
      setStartIndex(startIndex + numVisibleProducts);
    }
  };

  useEffect(() => {
    // Defina o número de produtos visíveis com base na largura da tela
    const handleResize = () => {
      if (window.outerWidth < 900) {
        setNumVisibleProducts(1);
      } else if (window.outerWidth < 1200) {
        setNumVisibleProducts(2);
      } else {
        setNumVisibleProducts(3);
      }
    };

    // Adicione um ouvinte de redimensionamento para atualizar o número de produtos visíveis
    window.addEventListener("resize", handleResize);
    handleResize(); // Chame a função uma vez para configurar o número inicial com base na largura atual

    return () => {
      // Remova o ouvinte de redimensionamento quando o componente for desmontado
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const visibleProducts = products.slice(startIndex, startIndex + numVisibleProducts);

  return (
    <div className="product-carousel">
      <div className="carousel-controls">
        <button className="carousel-left" onClick={handlePrevClick}><LeftIcon /></button>
      </div>
      <div className="product-carousel-two">
        <a href="#" style={{textDecoration: "none"}}>
          <div className="product-section-bottom">
            {visibleProducts.map((product, index) => (
              <div key={index} className="product-section-info">
                <img src={product.foto} alt="Imagem do produto" />
                <br></br>
                <h3 className="product-text-title">{product.name}</h3>
                <br></br>
                <span className="product-text-info">{product.description}</span>
              </div>
            ))}
          </div>
        </a>
      </div>
      <div className="carousel-controls">
        <button className="carousel-right" onClick={handleNextClick}><RightIcon /></button>
      </div>
    </div>
  );
};

export default ProductCarousel;