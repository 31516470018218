import React from "react";
import BannerBackground from "../Assets/fundo-azul.png";
import BannerImage from "../Assets/scanycarIII.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  return (
    <div id="home" className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            Scanycar III
          </h1>
          <p className="primary-text">
            Terceira geração do produto Scanycar para <b>diagnóstico automotivo</b>.
            Melhor relação custo-benefício do mercado, uma ferramenta robusta, rápida e confiável.
            Possui um visual moderno, com <b>alta tecnologia embarcada</b> e design ergonômico de fácil utilização.
          </p>
          <button className="secondary-button">
            Saiba mais <FiArrowRight />{" "}
          </button>
        </div>
        <a href="#" style={{textDecoration: "none"}}>
          <div className="home-image-section">
            <img src={BannerImage} alt="" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Home;
