import React, { useEffect } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const BackToTop = () => {

    useEffect(() => {
        initBackToTop();
      }, []);

    return (
        <div>
            <button id="backToTopBtn" title="">
                <ArrowUpwardIcon />
            </button>
        </div>
    );
};

export function initBackToTop() {
    const backToTopBtn = document.getElementById("backToTopBtn");
  
    // Show/hide the button based on scroll position
    window.addEventListener("scroll", function () {
      if (window.scrollY > 300) {
        backToTopBtn.style.display = "block";
      } else {
        backToTopBtn.style.display = "none";
      }
    });
  
    // Scroll to the top when the button is clicked
    backToTopBtn.addEventListener("click", function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
}  

export default BackToTop;