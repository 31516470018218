import React from "react";
import ProductCarousel from "./ProductCarousel";
import ScanycarIII from "../Assets/scanycarIII.png";
import Scanydrive from "../Assets/scanydrive.png";
import Jaltest from "../Assets/maleta-jaltest.png";
import Eeprom from "../Assets/programador-eeprom.png";
import Volteste from "../Assets/volteste.png";
import Pulsador from "../Assets/pulsador-de-bicos.jpg";
import Emulador from "../Assets/emulador-sonda-lambda.png";
import CanetaAzul from "../Assets/caneta-azul.png";
import Injetor from "../Assets/injetor-sinais.jpg";
import PincaSoic8 from "../Assets/pinca-soic-8-importada.jpg";
import Conversor from "../Assets/conversor-energia.png";
import Calibrador from "../Assets/calibrador.png";
import TesteBomba from "../Assets/teste-bomba-eletrica.jpg";

const ProductCatalog = () => {
  
  const products = [
    { foto: ScanycarIII, name: "Scanycar III", description: "Terceira geração do produto Scanycar para diagnóstico automotivo. Scanner com melhor relação custo-benefício do mercado." },
    { foto: Scanydrive, name: "Scanydrive", description: "Simulador de direção veicular Scanydrive é um dos únicos simuladores totalmente desenvolvido no Brasil." },
    { foto: Jaltest, name: "Jastest", description: "Diagnóstico e reprogramação eletrônica para veículos a diesel como: ônibus, caminhões, equipamentos agrícolas e outros." },
    { foto: Eeprom, name: "Eeprom", description: "Restaura, salva e copia informações nas memórias dos dispositivos eletrônicos do carro." },
    { foto: Volteste, name: "Volteste", description: "Realiza leitura e teste de todos os sensores da injeção eletrônica com precisão e facilidade de operação." },
    { foto: Pulsador, name: "Pulsador de Bicos C/ SOS", description: "Realiza limpeza  dos bicos monoponto e multiponto, teste de estanqueidade dos bicos monoponto. Tecla de Socorro para pulsar os bicos. Cabo para 1 e 4 bicos." },
    { foto: Emulador, name: "Emulador de Sonda", description: "Problemas com a Sonda Lambda? Quer ter certeza do defeito? Então tenha o emulador de sonda lambda!" },
    { foto: CanetaAzul, name: "Caneta de Polaridade", description: "Indispensável no dia-a-dia do reparador. Efetua testes com rapidez e segurança." },
    { foto: Injetor, name: "Injetor de Sinal", description: "Equipamento usado para simular os sensores Hall e Magnéticos. Bobina impulsora e sensor de rotação da roda fônica." },
    { foto: PincaSoic8, name: "Pinça SOIC 8", description: "Para linha de máquinas Scanycar III, com cabos e conectores DB 25. Lê memórias SOIC 8 soldado na placa de circuito." },
    { foto: Conversor, name: "Conversor de Energia", description: "Para ligar notebooks, carregador de celular, ferro de solda 127V nos veículos. Saída USB para 5V." },
    { foto: Calibrador, name: "Calibrador", description: "Medidor de pressão de pneus – 50 PSI para conferir a calibragem dos pneus." },
    { foto: TesteBomba, name: "Teste de Bomba Elétrica", description: "Testes de vazão, amperagem, pressão de trabalho, pressão máxima, intermitência e outros." },
  ];

  return (
    <div id="product" className="product-section-wrapper">
      <div className="product-section-top">
        <p className="primary-subheading">Catálogo de produtos</p>
        <h1 className="primary-heading">Produtos</h1>
        <p className="primary-text">
          Ferramentas e soluções automotivas.
        </p>
        <ProductCarousel products={products} />
      </div>
    </div>
  );
};

export default ProductCatalog;