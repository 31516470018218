import React from "react";
import Simulador from "../Assets/scanydrive.png";
import Brasil from "../Assets/brasil.png";
import { FiArrowRight } from "react-icons/fi";

const Simulator = () => {
  return (
    <div id="simulator" className="simulator-section-container">
      <div className="simulator-background-image-container">
        <img src={Brasil} alt="" />
      </div>
      <div className="simulator-section-image-container">
        <a href="#">
          <img src={Simulador} alt="" />
        </a>
      </div>
      <div className="simulator-section-text-container">
        <div className="simulator-section-text-container-two">
          <p className="primary-subheading">Scanydrive</p>
          <h1 className="primary-heading">
            Scanydrive Simulador de Direção Veicular
          </h1>
          <p className="primary-text">
          Produto <b>nacional</b> inovador, criado com um <b>software realista</b> que permite aos alunos vivenciarem experiências equivalentes às situações reais de trânsito.
          Construído com peças de <b>aço e chapas metálicas</b> reforçadas para o uso diário intenso.
          </p>
          <div className="simulator-buttons-container">
            <a href="https://www.scanydrive.com.br/">
              <button className="secondary-button">
                Ir para o site<FiArrowRight />{" "}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Simulator;