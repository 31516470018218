import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import ProductCatalog from "./Components/ProductCatalog";
import Simulator from "./Components/Simulator";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Whatsapp from "./Components/Whatsapp";
import BackToTop from "./Components/BackToTop";

const AppRoutes = () => {
  return (
    <div className="App">
      {/* <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/product" element={<ProductCatalog />} />
      <Route path="/simulator" element={<Simulator />} />
      <Route path="/contact" element={<Contact />} />
    </Routes> */}
    <Home />
    <Simulator />
    <ProductCatalog/>
    <About />
    <Contact />
    <Footer />
    <BackToTop />
    <Whatsapp />
  </div>
    
  );
};

const RoutesConfig = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default RoutesConfig;