import React from "react";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Atendente from "../Assets/recepcionista.png";

const Whatsapp = () => {
  return (
    <div className="whatsapp-container">
      <div className="whatsapp-item">
        <FloatingWhatsApp
          className="vendas-whatsapp"
          avatar={Atendente} 
          accountName="Scanitec" 
          phoneNumber="31 996562508"
          statusMessage="Central de Vendas"
          chatMessage={'Olá, seja bem vindo! 🤝\nComo podemos te ajudar?'}
          placeholder="Digite uma mensagem..."
        />
      </div>
      {/* <div className="whatsapp-item">
        <FloatingWhatsApp
          className="suporte-whatsapp" 
          avatar={Atendente} 
          accountName="Scanitec" 
          phoneNumber="31 996562508"
          statusMessage="Suporte Técnico"
          chatMessage={'Olá, como podemos ajudar?'}
          placeholder="Digite uma mensagem..."
        />
      </div> */}
    </div>
  );
};

export default Whatsapp;