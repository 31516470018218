import React, { useState } from "react";
import Logo from "../Assets/logo-sc.png";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import CarIcon from "@mui/icons-material/CarRental";
import Car2Icon from "@mui/icons-material/DirectionsCar";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PhoneRoundedIcon from "@mui/icons-material/PhoneIphone";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuItemClick = (sectionId) => {
    document.querySelector(sectionId).scrollIntoView({
      behavior: "smooth",
    });
    setOpenMenu(false);
  };

  return (
    <nav>
      <div className="nav-logo-container">
        <a href="#home">
          <img src={Logo} alt="Scanitec Equipamentos Automotivos Ltda" />
        </a>
        <span className="logo-description"></span>
      </div>
      <div className="navbar-links-container">
        <a className="navbar-link" onClick={() => handleMenuItemClick("#about")}>
          Empresa
        </a>
        <a className="navbar-link" onClick={() => handleMenuItemClick("#simulator")}>
          Scanydrive
        </a>
        <a className="navbar-link" onClick={() => handleMenuItemClick("#product")}>
          Produtos
        </a>
        <a className="navbar-link" onClick={() => handleMenuItemClick("#contact")}>
          Contato
        </a>
        <button className="primary-button">Consultar Planos</button>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        anchor="right"
      >
        <Box
          className="navbar-menu-container-box"
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            <ListItem id="itemMenuEmpresa" 
              className="navbar-menu-container-list" 
              onClick={() => handleMenuItemClick("#about")}>
              <ListItemIcon className="navbar-menu-container-list-icon">
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Empresa" />
            </ListItem>
            <ListItem id="itemMenuSimulador" 
              className="navbar-menu-container-list"
              onClick={() => handleMenuItemClick("#simulator")}>
             <ListItemIcon className="navbar-menu-container-list-icon">
                <Car2Icon />
              </ListItemIcon>
              <ListItemText primary="Scanydrive" />
            </ListItem>
            <ListItem id="itemMenuProdutos"
              className="navbar-menu-container-list"
              onClick={() => handleMenuItemClick("#product")}>
              <ListItemIcon className="navbar-menu-container-list-icon">
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Produtos" />
            </ListItem>
            <ListItem id="itemMenuPlanos"
              className="navbar-menu-container-list"
              onClick={() => handleMenuItemClick("#")}>
             <ListItemIcon className="navbar-menu-container-list-icon">
                <CarIcon />
              </ListItemIcon>
              <ListItemText primary="Planos" />
            </ListItem>
            <ListItem id="itemMenuContato"
              className="navbar-menu-container-list"
              onClick={() => handleMenuItemClick("#contact")}>
             <ListItemIcon className="navbar-menu-container-list-icon">
                <PhoneRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Contato" />
            </ListItem>
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;