import React from "react";
import Empresa from "../Assets/empresa.jpg";
import Fundo from "../Assets/fundo-azul-7.png";
import Youtube from "../Assets/youtube.jpg";
import { FiArrowRight } from "react-icons/fi";

const About = () => {
  return (
    <div id="about" className="about-section-container">
      <div className="about-bannerImage-container">
        <img src={Fundo} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">Sobre</p>
        <h1 id="subtitulo" className="primary-heading">
          Scanitec Equipamentos Automotivos Ltda
        </h1>
        <p className="primary-text">
          Empresa situada em <b>Belo Horizonte</b>, fundada na década de 90 com o objetivo de prover soluções para <b>diagnóstico</b> e <b>sensores automotivos</b>.
          Nossa linha de produtos é fabricada por especialistas comprometidos com o mercado e o desenvolvimento nacional.
        </p>
        <div className="about-buttons-container">
          <button className="secondary-button">
            Saiba mais <FiArrowRight />{" "}
          </button>
        </div>
      </div>
      <div className="about-section-image-container">
        <a href="#" style={{textDecoration: "none"}}>
          <img src={Empresa} alt="" />
        </a>
      </div>
    </div>
  );
};

export default About;
